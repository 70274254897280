import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const FilmmakerStarting5 = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Filmmaker Starting 5" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>Filmmaker Starting 5</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Nov. 3rd, 2023) </p>
        </div>
        <div className="articleBody">
          <p>
            In Plato’s <i>Republic</i>, the ancient philosopher expressed an
            evergreen insight on Man’s relationship to Culture, and I quote:
            “Every hooper wanna be a rapper, and every rapper wanna be a
            hooper.”{" "}
          </p>
          <p>The evidence is nearly endless.</p>
          <p>
            <a
              href="https://www.youtube.com/watch?v=2skYVPGExgY"
              target="_blank"
              rel="noopener noreferrer"
            >
              Allen Iverson
            </a>
            ,{" "}
            <a
              href="https://www.youtube.com/watch?v=dL6kQ0v1PQ8"
              target="_blank"
              rel="noopener noreferrer"
            >
              Shaquille O’Neal
            </a>
            , and even the late{" "}
            <a
              href="https://www.youtube.com/watch?v=cl_WkTiH6-Q"
              target="_blank"
              rel="noopener noreferrer"
            >
              Kobe Bryant
            </a>{" "}
            paved the way for the likes of{" "}
            <a
              href="https://www.youtube.com/watch?v=mP96O3iAQDM"
              target="_blank"
              rel="noopener noreferrer"
            >
              Damian Lillard
            </a>{" "}
            and
            <a
              href="https://www.youtube.com/watch?v=CQGo0bkgNxE"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Miles Bridges
            </a>
            .{" "}
          </p>
          <p>
            <a
              href="https://youtu.be/PDH_BI_29fI?si=QiLWIIYotyvnCovS&t=123"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cam’ron
            </a>{" "}
            (shoulda passed to Mase),{" "}
            <a
              href="https://www.youtube.com/watch?v=c6rKEhod24o"
              target="_blank"
              rel="noopener noreferrer"
            >
              2 Chainz
            </a>
            , and{" "}
            <a
              href="https://www.youtube.com/watch?v=S3Xn2z4W-sU"
              target="_blank"
              rel="noopener noreferrer"
            >
              Master P
            </a>{" "}
            made room for folks like{" "}
            <a
              href="https://www.youtube.com/watch?v=QHQFsMyqEiM"
              target="_blank"
              rel="noopener noreferrer"
            >
              J. Cole{" "}
            </a>
            and{" "}
            <a
              href="https://www.youtube.com/watch?v=TtwkuoaI2Iw"
              target="_blank"
              rel="noopener noreferrer"
            >
              Quavo
            </a>
            .
          </p>

          <p>
            But while there’s a rich history of basketball being depicted in
            cinema (<i>Hoop Dreams</i> and <i>He Got Game</i> are a few personal
            favorites), not as many filmmakers get their due on the hardwood
            when it comes to the mainstream (read: Black) zeitgeist. I suspect
            that’s cuz music and sports are the two most commonly pursued means
            of upward mobility in the inner city (YouTube commenter
            @PandaPlayZHD put it best on that RTB MB vid when they said: “This
            man had 2 options to make it out the hood and he took em both 😤”).{" "}
          </p>
          <p>
            So ard yeah whatever, anyway here go my starting five made up of my
            favorite filmmakers that get buckets. (I’m excluding triple threats
            like Cam’ron and Master P cuz to me they count as legendary indie
            filmmakers too 💯)
          </p>
          <p className="highlight">PG: Gina Prince-Bythewood </p>
          <p>
            As a filmmaker, Gina Prince-Bythewood easily counts as one of the
            greats. Her opus <i>Love & Basketball</i> (2000) is an indelible
            film in the get-buckets canon, and I’m sure that her skills as a
            director and her history as a track and basketball athlete would
            make her an excellent floor general for this lil squad I’m putting
            together. (Fun fact: she has a small cameo in{" "}
            <i>Love & Basketball</i> as an opposing player, which I thought only
            I had picked up on but Manohla Dargis{" "}
            <a
              href="https://www.nytimes.com/2020/07/28/movies/love-basketball-viewing-party.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              wrote about it
            </a>{" "}
            too!)
          </p>
          <p className="highlight">SG: Adam Sandler </p>
          <p>
            I could give y’all 5,000 words about Adam Sandler. He wears his love
            of the game on his sleeve, most recently in his love letter to the
            game <i>Hustle</i> (2022). One of my most unforgettable memories at
            the Telluride Film Festival is bumping into the Sandman outside the
            Sheridan Opera House the year that <i>Uncut Gems</i> premiered
            there. He hopped out of a black SUV in a leather jacket and boot cut
            jeans, noticed me (in a sea of white faces) and gave me a cool nod.
            I lie to you not, less than 2 hours later I caught him across town
            (it’s a small town) suited up in his trademark ball shorts playing
            two-on-two at the town park with some locals!!! I deeply regret not
            being able to jump in, but I get the sense that I’ll get to cook him
            one day. Anyway yeah after seeing him play in real life (and in
            clips online that people be texting me cuz I'm off socials) I can
            tell that having him as my shooting guard is the way to go, that’s
            5’10” of pure heart right there.
          </p>
          <p className="highlight">SF: Yorgos Lanthimos</p>
          <p>
            <a
              href="http://www.lanthimos.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Yorgos Lanthimos
            </a>{" "}
            is easily one of my favorite working directors. He creates singular,
            surreal, hilarious worlds, and I doubt we’ve even seen the height of
            his powers yet (I’m dying to see <i>Poor Things</i> when it comes
            out later this year). If you've seen any of his films, though, it
            may surprise you that this harbinger of the bizarre was once a
            professional basketball player in his native Greece. At a healthy
            6’2”, he even reached a higher tier of the Greek league than 2X NBA
            MVP Award winner Giannis Antetokounmpo (ain’t that crazy?!!). Given
            the style of his work, I think that qualifies him as the original
            Greek Freak forreal.{" "}
          </p>
          <p className="highlight">PF: Mahershala Ali</p>
          <p>
            Mahershala, my brother in Islam, I need you on the squad! Before
            winning two Oscars, 6’4” Mahershala Ali{" "}
            <a
              href="https://bleacherreport.com/articles/2695571-how-mahershala-ali-of-moonlight-found-his-true-passion-after-d-i-basketball"
              target="_blank"
              rel="noopener noreferrer"
            >
              played D1 basketball
            </a>{" "}
            at Saint Mary’s. Now he’s one of the most compelling thespians of
            his generation, which is great cuz I need him to fool the defense! I
            listened to the A24 podcast episode between him and Ramy Youssef
            (speaking of <i>Poor Things</i>), and Ramy claimed that he beat
            Mahershala one-on-one but uhhhhh I doubt it LOL. Til that footage
            drops I’m taking Hershal all day.{" "}
          </p>
          <p className="highlight">C: RaMell Ross</p>
          <p>
            For those who don’t know,{" "}
            <a
              href="https://www.ramellross.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              RaMell Ross
            </a>{" "}
            is a brilliant documentary filmmaker, best known for his
            Oscar-nominated film <i>Hale County This Morning, This Evening</i>{" "}
            (2018) (which I should have included{" "}
            <a
              href="https://shoya.co/shoyaright/vol2/black-nonfiction-cinema"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            ). I had the pleasure to meet the visionary cinematographer{" "}
            <a
              href="https://www.jomofray.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jomo Fray
            </a>{" "}
            (who also lensed this year's{" "}
            <a
              href="https://a24films.com/films/all-dirt-roads"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>All Dirt Roads Taste of Salt</i>
            </a>{" "}
            , a masterpiece of a debut by legend-in-the-making{" "}
            <a
              href="https://raven-jackson.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Raven Jackson
            </a>
            ) at this past Indie Memphis Film Festival, where he revealed that
            he recently wrapped shooting on Ross’ first narrative feature film
            (!!!) which I’m also very excited to see. But don’t be fooled by the
            glasses and endless vocabulary, my man RaMell (who stands at 6’6”)
            was recruited to join one of the most legendary teams in college
            hoops history. Though his{" "}
            <a
              href="https://guhoyas.com/sports/mens-basketball/roster/ramell-ross/676"
              target="_blank"
              rel="noopener noreferrer"
            >
              time as a Hoya
            </a>{" "}
            was punctuated by various injuries, I don’t doubt he could secure a
            double-double if cheered on by this here team of auteurs. I also{" "}
            <a
              href="https://andscape.com/features/former-georgetown-basketball-player-turns-passion-into-acclaimed-documentary/"
              target="_blank"
              rel="noopener noreferrer"
            >
              read
            </a>{" "}
            that he coached the game in Hale County, which would no doubt come
            in handy.
          </p>

          <p>
            And since I'm playing GM, let me flesh out a couple honorable
            mentions too.
          </p>

          <p>
            <span className="highlight">Head Coach: Spike Lee</span> <br /> Let
            Spike tell it and he coulda coached Julius Randle to five rings by
            now! Plus my OG too seasoned to be lacing up at this point, much
            love to the legend.
          </p>
          <p>
            <span className="highlight">Sixth Player: Eddie Huang</span> <br />
            I'll admit I still haven't gotten a chance to see <i>Boogie</i>{" "}
            (2021) but Eddie Huang is one of the great Renaissance men of our
            time I gotta give him a shot off the bench for sure.
          </p>
          <p>
            I realized while writing this that basketball doesn’t have a cool
            nickname like soccer (”The Beautiful Game”) or boxing (”The Sweet
            Science”) or baseball ("The National Pastime") so I hereby coin the
            phrase “Chess In the Air” to describe my favorite sport (
            <a
              href="https://www.youtube.com/watch?v=_shxzlTRK44"
              target="_blank"
              rel="noopener noreferrer"
            >
              I like the way they dribble up and down the court
            </a>
            ).
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020 - &infin;</p>
        </div>
      </div>
    </div>
  )
}
export default FilmmakerStarting5
